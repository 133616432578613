import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  Data,
  UserManagementResponse,
} from '../responnse/user_management_response';

const initialState: UserManagementResponse = {
  success: false,
  message: '',
  data: null,
};

export const authenticationSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setUserManagementDatas: (state, action: PayloadAction<Data | null | undefined>) => {
      state.data = action.payload;
    },
  },
});

export const { setUserManagementDatas } = authenticationSlice.actions;

export default authenticationSlice.reducer;
