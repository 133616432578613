import React, { useEffect, useState } from 'react';
import {
  OptionContainer,
  PaginationWrapper,
  RateContainer,
  Select,
  TableTr,
  UserImageContainer,
  UserListSearchbarContainer,
  UserListTableHeader,
  UserListTableHeaderText,
  UserListTableText,
  UserListWrapper,
  UserManagementOverview,
  UserOptionContainer,
  UserProfilePicsAndNameContainer,
  UserStatusContainer,
  UserTypeContainer,
  UserTypeContainerFisrstRow,
  Option,
  UserTypeContainerThirdRow,
  UserTypeIconContainer,
  UserTypeSectionWrapper,
  UserDemographicContainer,
  UserDemographicByAge,
  UserDemographicByGender,
} from './overview_styles';
import {
  userListHeaderText,
  userTypes,
} from '../../controller/user_management_config';
import ReactPaginate from 'react-paginate';
import { SpowseImage } from '../../../../core/common/reuseables/spowse_image';
import SpowseTextField from '../../../../core/common/reuseables/spowse_textfield';
import {
  Heading1,
  CommonText,
} from '../../../../core/common/reuseables/text_styles';
import { AppIcons } from '../../../../core/utilities/app_icons';
import { theme } from '../../../../theme/theme';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { revenueMadeByAge } from '../../../revenue/controller/revenue_config';
import {
  DeviceOsBarChartContainer,
  DeviceOsContainer,
} from '../../../dashboard/widgets/Visitors_and_Device_type/VisitorsAndDevice_styles';
import { Divider } from '../../../../core/common/reuseables/Divider';
import { SpowseButton } from '../../../../core/common/reuseables/spowse_button';
import { SearchAndButtonContainer } from '../../../campaigns/widdets/campaign_section/CampignSection_styles';
import Modal from '../../../../core/common/reuseables/modal';
import { HeightGap } from '../../../../core/common/reuseables/gap';
import ImagePicker from '../../../../core/common/reuseables/image_picker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../logic/redux_store';
import { Data, UserManagementResponse } from '../../../../logic/responnse/user_management_response';
import { data1, formatValue1, getInitials } from '../../controller/logic';
import ApiService from '../../../../core/api/api';
import { ApiUrl } from '../../../../core/api/api_url';
import { getSecureItem } from '../../../../core/database/cryto';
import { SecureDataBaseUrl } from '../../../../core/database/secure_database_key';
import { VerifyLoginEmailResponse } from '../../../../logic/responnse/verify_login_email_response';
import ContentLoader from 'react-content-loader';
import { setUserManagementDatas } from '../../../../logic/slices/user_management_slice';

type OverviewProps = {};

const Overview: React.FC<OverviewProps> = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [userManagementData, setUserManagementData] = useState<Data | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 10;
  const period = 'allTime';
  const dispatch = useDispatch();
  const isUserData = useSelector(
    (state: RootState) => state.userManagement.data,
  );


  const handlePageChange = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected + 1);
  };


  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                    M${x},${y + height}
                    L${x},${y + radius}
                    Q${x},${y} ${x + radius},${y}
                    L${x + width - radius},${y}
                    Q${x + width},${y} ${x + width},${y + radius}
                    L${x + width},${y + height}
                    Z
                  `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  useEffect(() => {
    const apiService = new ApiService();
    if (isUserData != null) {
      setIsLoading(false);
      setUserManagementData(isUserData);
      const fetchUsers = async () => {
        const userDatas: VerifyLoginEmailResponse = await getSecureItem(SecureDataBaseUrl.USER_DATA);
        const headers = {
          "Authorization": `Bearer ${userDatas.accessToken}`,
        };

        const response = await apiService.getData<UserManagementResponse>(ApiUrl.getUsers, {
          page: currentPage,
          limit: limit,
          period: 'allTime'
        }, headers);

        if (response.success) {
          setIsLoading(false);
          setUserManagementData(response?.data?.data);
          dispatch(setUserManagementDatas(response?.data?.data));
          setTotalCount(response?.data?.data?.users?.totalCount[0].total ?? 0);
          setTotalPages(Math.ceil(totalCount / limit));
        } else {
          setIsLoading(false);
          console.error('Error:', response.message);
        }
      };

      fetchUsers();

    } else {

      const fetchUsers = async () => {
        const userDatas: VerifyLoginEmailResponse = await getSecureItem(SecureDataBaseUrl.USER_DATA);
        const headers = {
          "Authorization": `Bearer ${userDatas.accessToken}`,
        };

        const response = await apiService.getData<UserManagementResponse>(ApiUrl.getUsers, {
          page: currentPage,
          limit: limit,
          period: period
        }, headers);

        if (response.success) {
          setIsLoading(false);
          setUserManagementData(response?.data?.data);
          dispatch(setUserManagementDatas(response?.data?.data));
          setTotalCount(response?.data?.data?.users?.totalCount[0].total ?? 0);
          setTotalPages(Math.ceil(totalCount / limit));
        } else {
          setIsLoading(false);
          console.error('Error:', response.message);
        }
      };

      fetchUsers();
    }
  }, [isUserData, dispatch, limit, period, totalCount, currentPage])

  return (
    <UserManagementOverview>

      <Modal title="New User" isOpen={isModalOpen} onClose={closeModal}>
        <SpowseTextField
          label="Name"
          placeHolder="Enter full name"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Email Address"
          placeHolder="Enter email Address"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="User Role"
          placeHolder="Enter role name"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <ImagePicker />
        <SpowseButton
          width="100%"
          height="38px"
          fontSize="14px"
          fontWeight="400"
          fontFamily="matter-light"
          onClick={openModal}
        >
          Create User
        </SpowseButton>
      </Modal>
      {isLoading ? (
        <div style={{ width: "100%" }}>
          <UserTypeSectionWrapper>
            <ContentLoader
              speed={2}
              width="100%"
              height={160}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
            </ContentLoader>
            <ContentLoader
              speed={2}
              width="100%"
              height={160}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
            </ContentLoader>
            <ContentLoader
              speed={2}
              width="100%"
              height={160}
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
            </ContentLoader>
          </UserTypeSectionWrapper>
          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <UserDemographicContainer width='49%'>
              <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox="0 0 400 160"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
              </ContentLoader>
            </UserDemographicContainer>
            <UserDemographicContainer width='49%'>
              <ContentLoader
                speed={2}
                width="100%"
                height="100%"
                viewBox="0 0 400 160"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
              </ContentLoader>
            </UserDemographicContainer>
          </div>
          <div style={{ marginTop: "1.5rem", width: "100%" }}>
            <ContentLoader
              speed={2}
              width="100%"
              height="100%"
              viewBox="0 0 400 160"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="5" ry="5" width="400" height="160" />
            </ContentLoader>
          </div>
        </div>

      ) : (<div style={{ width: "100%" }}>
        <UserTypeSectionWrapper>
          {userTypes.map((userType, index) => {
            return (
              <UserTypeContainer key={index}>
                <UserTypeContainerFisrstRow>
                  <UserTypeIconContainer
                    background={userType.iconBackgroundColor}
                  >
                    <SpowseImage src={userType.icon} alt="img" width="auto" />
                  </UserTypeIconContainer>
                  <Select id="options" name="options">
                    <Option value="This month">This month</Option>
                    <Option value="Today">Today</Option>
                    <Option value="7 day ago">7 day ago</Option>
                    <Option value="Last month">Last month</Option>
                  </Select>
                </UserTypeContainerFisrstRow>
                <Heading1
                  fontFamily="matter-bold"
                  fontWeight="600"
                  color={`${theme.colors.linkActiveTextColor}`}
                  fontSize="20px"
                  lineHeight="30px"
                >
                  {userType.userCount}
                </Heading1>
                <UserTypeContainerThirdRow>
                  <CommonText
                    fontFamily="matter-medium"
                    fontWeight="500"
                    color={`${theme.colors.linkInActiveTextColor}`}
                    fontSize="14px"
                    lineHeight="20px"
                  >
                    {userType.userType}
                  </CommonText>
                  <RateContainer>
                    <CommonText
                      fontFamily="matter-medium"
                      fontWeight="500"
                      fontSize="12px"
                      lineHeight="18px"
                      color="#0F973D"
                    >
                      {userType.rate}
                    </CommonText>
                  </RateContainer>
                </UserTypeContainerThirdRow>
              </UserTypeContainer>
            );
          })}
        </UserTypeSectionWrapper>
        <UserDemographicContainer>
          <UserDemographicByAge>
            <CommonText
              fontSize="20px"
              fontWeight="500"
              color={`${theme.colors.linkActiveTextColor}`}
              fontFamily="matter-medium"
            >
              User Demographic By age
            </CommonText>
            <CommonText
              fontSize="12px"
              fontWeight="400"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontFamily="matter-regular"
            >
              No of user
            </CommonText>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart data={revenueMadeByAge}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" fontSize="12px" tickLine={false} />
                <YAxis
                  fontSize="12px"
                  width={35}
                  tickLine={false}
                  axisLine={false}
                  tickFormatter={formatValue}
                />
                <Tooltip cursor={false} />
                <Bar
                  dataKey="pv"
                  fill="#165DFF"
                  shape={<CustomBar />}
                  barSize={40}
                />
              </BarChart>
            </ResponsiveContainer>
          </UserDemographicByAge>
          <UserDemographicByGender>
            <CommonText
              fontSize="20px"
              fontWeight="500"
              color={`${theme.colors.linkActiveTextColor}`}
              fontFamily="matter-bold"
            >
              User Demographic by Gender
            </CommonText>
            <Divider
              width="100%"
              height="1px"
              backgroundColor={`${theme.colors.borderColor}`}
            />
            <DeviceOsBarChartContainer height="300px">
              <DeviceOsContainer>
                <CommonText
                  fontSize="14px"
                  fontWeight="400"
                  color={`${theme.colors.linkActiveTextColor}`}
                  lineHeight="22px"
                >
                  Male
                </CommonText>
                <CommonText
                  fontSize="14px"
                  fontWeight="400"
                  color={`${theme.colors.linkActiveTextColor}`}
                  lineHeight="22px"
                >
                  Female
                </CommonText>
              </DeviceOsContainer>
              <ResponsiveContainer width="70%" height="70%">
                <BarChart data={data1} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                  <XAxis
                    type="number"
                    domain={[0, 1000000]}
                    tickFormatter={formatValue1}
                    tickLine={false}
                    axisLine={false}
                    fontSize="12px"
                    fontWeight="400"
                    color={`${theme.colors.linkInActiveTextColor}`}
                    fontFamily="matter-regular"
                  />
                  <YAxis type="category" dataKey="name" hide tickLine={false} />
                  <Tooltip cursor={false} />
                  <Bar dataKey="pv" fill="#6B8BFF" barSize={56}>
                    <LabelList
                      dataKey="pv"
                      position="right"
                      formatter={formatValue}
                      fontSize="14px"
                      fontWeight="400"
                      color={`${theme.colors.linkActiveTextColor}`}
                      fontFamily="matter-regular"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </DeviceOsBarChartContainer>
            <CommonText
              style={{
                marginLeft: '30%',
                marginTop: '10px',
              }}
              fontSize="14px"
              fontWeight="400"
              color={`${theme.colors.linkActiveTextColor}`}
              fontFamily="matter-regular"
            >
              Percentage of Active Users
            </CommonText>
          </UserDemographicByGender>
        </UserDemographicContainer>
        {/* user lists */}
        <UserListWrapper>
          <UserListSearchbarContainer>
            <CommonText
              fontSize="18px"
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight="500"
            >
              Users
            </CommonText>
            <SearchAndButtonContainer>
              <SpowseTextField
                placeHolder="Search"
                width="320px"
                height="45px"
                borderColor="transparent"
                prefixIxon={
                  <SpowseImage
                    src={AppIcons.searchIcon}
                    alt="search icon"
                    width="auto"
                  />
                }
              />
              <SpowseButton
                width="135px"
                height="38px"
                fontSize="14px"
                fontWeight="400"
                fontFamily="matter-light"
                onClick={openModal}
              >
                New User
              </SpowseButton>
            </SearchAndButtonContainer>
          </UserListSearchbarContainer>
          <table
            style={{
              width: '100%',
              borderCollapse: 'collapse',
              border: '0',
              outline: 'none',
              marginTop: '2rem',
            }}
          >
            <UserListTableHeader>
              <tr>
                {userListHeaderText.map((e, index) => {
                  return (
                    <UserListTableHeaderText
                      key={index}
                      style={{
                        width: index === 0 ? '100px' : 'auto',
                        paddingLeft: index === 0 ? '1.2rem' : '9px',
                      }}
                    >
                      {e}
                    </UserListTableHeaderText>
                  );
                })}
              </tr>
            </UserListTableHeader>
            <tbody>
              {userManagementData?.users.allUsers.map((row, index) => (
                <TableTr key={index}>
                  <UserListTableText
                    style={{ width: '100px', paddingLeft: '1.2rem' }}
                  >
                    {index + 1}
                  </UserListTableText>
                  <UserListTableText>
                    <UserProfilePicsAndNameContainer>
                      <UserImageContainer>
                        {getInitials(`${row.firstName}o`)}
                      </UserImageContainer>
                      <CommonText
                        fontWeight="400"
                        fontSize=" 14px"
                        color={`${theme.colors.linkActiveTextColor}`}
                        fontFamily="matter-regular"
                        style={{
                          width: '100px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {`${row.firstName}`}
                      </CommonText>
                    </UserProfilePicsAndNameContainer>
                  </UserListTableText>
                  <UserListTableText>
                    <CommonText
                      fontWeight="400"
                      fontSize=" 14px"
                      color={`${theme.colors.linkActiveTextColor}`}
                      fontFamily="matter-light"
                      style={{
                        width: '200px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {row.email}
                    </CommonText>
                  </UserListTableText>
                  <UserListTableText style={{ width: '200px' }}>
                    {row.phoneNumber}
                  </UserListTableText>
                  <UserListTableText style={{ width: '200px' }}>{row.location}</UserListTableText>
                  <UserListTableText style={{ width: '200px' }}>
                    User
                  </UserListTableText>
                  <UserListTableText>
                    <UserStatusContainer status={"Active"}>
                      Active
                    </UserStatusContainer>
                  </UserListTableText>
                  <UserListTableText>
                    <UserOptionContainer>
                      <OptionContainer>
                        <SpowseImage
                          src={AppIcons.optionIcon}
                          alt="img"
                          width="auto"
                        />
                      </OptionContainer>
                    </UserOptionContainer>
                  </UserListTableText>
                </TableTr>
              ))}
            </tbody>
          </table>
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={`Previous`}
              nextLabel={'Next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={'pagination'}
              forcePage={currentPage - 1}
              nextClassName="n"
              activeClassName={'activeClass'}
            />
          </PaginationWrapper>
        </UserListWrapper>
      </div>)}
    </UserManagementOverview>
  );
};
export default Overview;
