import React, { useEffect, useState } from "react";
import {
  CampaignContent,
  CampaignContentWrapper,
  CampaignHeader,
  CampaignHeaderButton,
  CampaignWrapper,
} from "./Campaigns_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utilities/app_icons";
import { CommonText } from "../../core/common/reuseables/text_styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../logic/redux_store";
import { setCampaignTypeState } from "../../logic/slices/campaign_slice";
import { theme } from "../../theme/theme";
import OverviewSection from "./widdets/overview_section/OverviewSection";
import CampaignSection from "./widdets/campaign_section/CampaignSection";
import PatnershipSection from "./widdets/patnership_section/PatnershipSection";
import NewsletterSection from "./widdets/newsletter_section/NewsletterSection";
import ExpirationChecker from "../../core/common/reuseables/ExpirationChecker";

type CampaignsProps = {};

const Campaigns: React.FC<CampaignsProps> = () => {
  const [campaignType, setCampaignType] = useState("");
  const dispatch = useDispatch();
  const campaignTypeState = useSelector(
    (state: RootState) => state.campaign.campaignType
  );

  const handleCampaignType = (value: string) => {
    setCampaignType(value);
    dispatch(setCampaignTypeState(value));
  };

  useEffect(() => {
    const handleSetCampaignType = () => {
      setCampaignType(campaignTypeState);
    };
    handleSetCampaignType();
  }, [campaignTypeState]);

  return (
    <ExpirationChecker>
      <CampaignWrapper>
        <SideBar />
        <Header headingText="Campaigns" />
        <CampaignContentWrapper>
          <CampaignContent>
            <CampaignHeader>
              <CampaignHeaderButton
                background={
                  campaignType === "overview"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  campaignType === "overview"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  campaignType === "overview"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleCampaignType("overview");
                }}
              >
                <SpowseImage
                  src={AppIcons.overview}
                  alt="icon"
                  width="auto"
                  color={`${campaignType === "overview"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${campaignType === "overview"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Overview
                </CommonText>
              </CampaignHeaderButton>
              <CampaignHeaderButton
                background={
                  campaignType === "campaign"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  campaignType === "campaign"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  campaignType === "campaign"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleCampaignType("campaign");
                }}
              >
                <SpowseImage
                  src={AppIcons.campaignIcon}
                  alt="icon"
                  width="auto"
                  color={`${campaignType === "campaign"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${campaignType === "campaign"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Campaign
                </CommonText>
              </CampaignHeaderButton>
              <CampaignHeaderButton
                background={
                  campaignType === "partnership"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  campaignType === "partnership"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  campaignType === "partnership"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleCampaignType("partnership");
                }}
              >
                <SpowseImage
                  src={AppIcons.partnership}
                  alt="icon"
                  width="auto"
                  color={`${campaignType === "partnership"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${campaignType === "partnership"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Partnership
                </CommonText>
              </CampaignHeaderButton>
              <CampaignHeaderButton
                background={
                  campaignType === "newsletter"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  campaignType === "newsletter"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  campaignType === "newsletter"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleCampaignType("newsletter");
                }}
              >
                <SpowseImage
                  src={AppIcons.newsletterIcon}
                  alt="icon"
                  width="auto"
                  color={`${campaignType === "newsletter"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${campaignType === "newsletter"
                      ? `${theme.colors.linkActiveTextColor}`
                      : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Newsletter
                </CommonText>
              </CampaignHeaderButton>
            </CampaignHeader>
            {campaignType === "overview" ? (
              <OverviewSection />
            ) : campaignType === "campaign" ? (
              <CampaignSection />
            ) : campaignType === "newsletter" ? (
              <NewsletterSection />
            ) : (
              <PatnershipSection />
            )}
          </CampaignContent>
        </CampaignContentWrapper>
      </CampaignWrapper>
    </ExpirationChecker>
  );
};
export default Campaigns;
