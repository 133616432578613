export const getInitials = (name: string) => {
  const nameParts = name.split(' ');

  if (nameParts.length > 1) {
    return `${nameParts[0][0]}${nameParts[1][0]}`;
  } else {
    return nameParts[0].substring(0, 2);
  }
};



export const formatValue = (value: number) => {
  return value.toLocaleString();
};

export const formatValue1 = (value: number) => {
  if (value >= 1_000_000_000) {
    return `${(value / 1_000_000_000).toFixed(1)}B`;
  } else if (value >= 1_000_000) {
    return `${(value / 1_000_000).toFixed(1)}M`;
  } else if (value >= 1_000) {
    return `${(value / 1_000).toFixed(1)}k`;
  }
  return value.toString();
};

export const data1 = [
  { name: 'Female', uv: 4000, pv: 500400, amt: 2800 },
  { name: 'Male', uv: 3000, pv: 300398, amt: 2210 },
];