import React, { ReactNode, useState } from "react";
import { CustomLink, ErrorContainer, LogOutContainer, SideBarContent, SideBarWrapper } from "./SideBar_styles";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utilities/app_icons";
import { Divider } from "../../core/common/reuseables/Divider";
import { HeightGap } from "../../core/common/reuseables/gap";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { CommonText } from "../../core/common/reuseables/text_styles";
import { theme } from "../../theme/theme";
import styled from "styled-components";
import { SpowseButton } from "../../core/common/reuseables/spowse_button";
import { SecureDataBaseUrl } from "../../core/database/secure_database_key";

type SideBarProps = {};

const SideBar: React.FC<SideBarProps> = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <SideBarWrapper>
      <Modal title="Create Newsletter" isOpen={isModalOpen} onClose={closeModal}>
        <ErrorContainer>
          <SpowseImage src="/assets/icons/octicon_alert-24.svg" alt="img" width="auto" />
        </ErrorContainer>
        <HeightGap height="10px" />
        <CommonText
          fontFamily={"matter-bold"}
          fontSize="30px"
        >Logout
        </CommonText>
        <HeightGap height="10px" />
        <CommonText
          fontSize="14px"
          color={`${theme.colors.linkInActiveTextColor}`}
        >Are you sure you want to Logout</CommonText>
        <HeightGap height="1rem" />
      </Modal>
      <SideBarContent>
        <SpowseImage src={AppIcons.spowseFullLogo} alt="img" width="auto" />
        <HeightGap height="1.5rem" />
        <Divider height="1px" width="100%" />
        <HeightGap height="2rem" />
        <CustomLinks
          icon={AppIcons.dashboardIcon}
          linkText="Dashboard"
          onTap={() => { }}
          to="/"
        />
        <CustomLinks
          icon={AppIcons.userIcon}
          linkText="User management"
          onTap={() => { }}
          to="/user_management"
        />
        <CustomLinks
          icon={AppIcons.subscriptionIcon}
          linkText="Subscription"
          onTap={() => { }}
          to="/subscription"
        />
        <CustomLinks
          icon={AppIcons.userLog}
          linkText="User Logs"
          onTap={() => { }}
          to="/user_logs"
        />
        <CustomLinks
          icon={AppIcons.campaignIcon}
          linkText="Campaigns"
          onTap={() => { }}
          to="/campaigns"
        />
        <CustomLinks
          icon={AppIcons.financeIcon}
          linkText="Revenue"
          onTap={() => { }}
          to="/revenue"
        />
        <CustomLinks
          icon={AppIcons.marketingIcon}
          linkText="Marketing"
          onTap={() => { }}
          to="/marketing"
        />
        <CustomLinks
          icon={AppIcons.blogIcon}
          linkText="Blog post"
          onTap={() => { }}
          to="/blog_post"
        />
        <HeightGap height="200px" />
        <CustomLinks
          icon={AppIcons.settingIcon}
          linkText="Settings"
          onTap={() => { }}
          to="/settings"
        />
        <LogOutContainer onClick={openModal}>
          <SpowseImage src={AppIcons.logOutIcon} width="auto" />
          <CommonText
            fontFamily="matter-medium"
            color={`${theme.colors.linkInActiveTextColor}`
            }
            fontSize="14px"
            lineHeight="20px"
          >
            Logout
          </CommonText>
        </LogOutContainer>
      </SideBarContent>
    </SideBarWrapper>
  );
};
export default SideBar;

interface CustomLinksProps {
  to: string;
  icon: any;
  linkText: string;
  onTap?: any;
}

function CustomLinks({ to, onTap, icon, linkText }: CustomLinksProps) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <CustomLink to={to} active={isActive} onClick={onTap}>
      <SpowseImage src={icon} width="auto" />
      <CommonText
        fontFamily="matter-medium"
        color={
          isActive
            ? `${theme.colors.linkActiveTextColor}`
            : `${theme.colors.linkInActiveTextColor}`
        }
        fontSize="14px"
        lineHeight="20px"
      >
        {linkText}
      </CommonText>
    </CustomLink>
  );
}


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  width?: string;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255, 255, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8;
`;

const ModalContent = styled.div<{ width?: string }>`
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: ${(probs) => probs.width || "588px"};
  max-width: 1000px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid ${theme.colors.borderColor};
  z-index: 9;
  display:flex ;
  align-items:center ;
  justify-content:center ;
  flex-direction:column ;
`;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, width }) => {
  const navigate = useNavigate();
  const logout = () => {
    try {
      localStorage.removeItem(SecureDataBaseUrl.USER_DATA);
      navigate("/login");
    } catch (e) { console.log(e); }


  }
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e: any) => e.stopPropagation()} width={width}>
        {children}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SpowseButton
            width="49%"
            height="50px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
            backgroundColor={"red"}
            onClick={logout}
          >
            Logout
          </SpowseButton>
          <SpowseButton
            width="49%"
            height="50px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
            backgroundColor="white"
            textColor={`${theme.colors.linkActiveTextColor}`}
            border={`1px solid ${theme.colors.borderColor}`}
            onClick={onClose}
          >
            Cancel
          </SpowseButton>
        </div>
      </ModalContent>
    </ModalOverlay>
  );
};

