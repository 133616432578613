export class ApiUrl {
  ///
  //authentication
  static onnboardAdmin = '/auth/onboard-admin';
  static login = '/auth/login';
  static requestRegistrationLink = '/auth/request-registration-link';
  static verifyEmail = '/auth/verify-registration-link';
  static verifyLogin = '/auth/verify-login-session';

  ///
  //Users
static getUsers = "/users";
}
