import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './slices/authentication_slice';
import campaignReducer from './slices/campaign_slice';
import userManagementReducer from './slices/user_management_slice';

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    campaign: campaignReducer,
    userManagement: userManagementReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
