import styled from "styled-components";
import { theme } from "../../theme/theme";

export const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 4;
`;

export const HeaderContentWrapper = styled.div`
  padding-top: 1.2rem;
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  width: ${theme.widths.contentWidth};
  display: flex;
  background: ${theme.colors.backgroundColor};
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1300px){
    width: 83%;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  background: ${theme.colors.white};
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
  padding-top: 1.3rem;
  padding-bottom: 1.5rem;
  padding-right: 2rem;
  padding-left: 1rem;
  align-items: center;
  justify-content: space-between;
  height: 77px;
  border-bottom: 2px solid ${theme.colors.backgroundColor};
`;

export const HeaderRightSection = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderNotificationIconContainer = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.backgroundColor};
  border-radius: 100%;
  margin-right: 2rem;
`;

export const HeaderUserContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const HeaderProfileImageContainer = styled.div`
  width: 40px;
  height: 40px;
  background-image: url("/assets/images/profile_image.jpeg");
  background-size: cover;
  border-radius: 100%;
`;

export const HeaderUserNameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
