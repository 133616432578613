import React, { ReactNode } from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { CommonText } from './text_styles';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  width?:string;
}

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255, 255, 0.4);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
`;

const ModalContent = styled.div<{width?:string}>`
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: ${(probs) => probs.width || "588px"};
  max-width: 1000px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  border: 1px solid ${theme.colors.borderColor};
  z-index: 7;
`;
const CloseRow = styled.div`
width: 100%;
display: flex ;
align-items: center;
justify-content:space-between ;
margin-bottom:1rem ;
`;
const CloseContainer = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color:black ;
  display: flex ;
  align-items:center ;
  justify-content:center ;
  cursor: pointer;

  &>:nth-child(1){
   width: 20px ;
  height: 20px;
  }
`;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, title, width }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e: any) => e.stopPropagation()} width={width}>
        <CloseRow>
          <CommonText
            fontFamily={"matter-regular"}
            fontSize="30px"
            fontWeight='600'
          >{title}</CommonText>
          <CloseContainer onClick={onClose}>
            <MdClose color='white'/>
          </CloseContainer>
        </CloseRow>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

export default Modal;
