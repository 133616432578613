import React, { useState } from "react";
import {
  OptionContainer,
  PaginationWrapper,
  TableTr,
  UserImageContainer,
  UserLogsContent,
  UserLogsContentWrapper,
  UserLogsDataWrapper,
  UserLogsSearchbarContainer,
  UserLogsTableHeader,
  UserLogsTableHeaderText,
  UserLogsTableText,
  UserLogsWrapper,
  UserOptionContainer,
  UserProfilePicsAndNameContainer,
  UserStatusContainer,
} from "./UserLogs_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import ReactPaginate from "react-paginate";
import { AppIcons } from "../../core/utilities/app_icons";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { CommonText } from "../../core/common/reuseables/text_styles";
import SpowseTextField from "../../core/common/reuseables/spowse_textfield";
import {
  userLogsData,
  userLogsHeaderText,
} from "./controllers/user_logs_config";
import { theme } from "../../theme/theme";
import ExpirationChecker from "../../core/common/reuseables/ExpirationChecker";

type UserLogsProps = {};

const UserLogs: React.FC<UserLogsProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayUsers = userLogsData.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const getInitials = (name: string) => {
    const nameParts = name.split(" ");

    if (nameParts.length > 1) {
      return `${nameParts[0][0]}${nameParts[1][0]}`;
    } else {
      return nameParts[0].substring(0, 2);
    }
  };

  return (
    <ExpirationChecker>
      <UserLogsWrapper>
        <SideBar />
        <Header headingText="User Logs" />
        <UserLogsContentWrapper>
          <UserLogsContent>
            <UserLogsDataWrapper>
              <UserLogsSearchbarContainer>
                <CommonText
                  fontSize="18px"
                  color={`${theme.colors.linkActiveTextColor}`}
                  fontWeight="500"
                >
                  Users
                </CommonText>
                <SpowseTextField
                  placeHolder="Search"
                  width="320px"
                  height="45px"
                  borderColor="transparent"
                  prefixIxon={
                    <SpowseImage
                      src={AppIcons.searchIcon}
                      alt="search icon"
                      width="auto"
                    />
                  }
                />
              </UserLogsSearchbarContainer>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "0",
                  outline: "none",
                  marginTop: "2rem",
                }}
              >
                <UserLogsTableHeader>
                  <tr>
                    {userLogsHeaderText.map((e, index) => {
                      return (
                        <UserLogsTableHeaderText
                          key={index}
                          style={{
                            paddingLeft: index === 0 ? "1.2rem" : "9px",
                          }}
                        >
                          {e}
                        </UserLogsTableHeaderText>
                      );
                    })}
                  </tr>
                </UserLogsTableHeader>
                <tbody>
                  {displayUsers.map((row, index) => (
                    <TableTr key={index}>
                      <UserLogsTableText
                        style={{ width: "50px", paddingLeft: "1.2rem" }}
                      >
                        {index + 1}
                      </UserLogsTableText>
                      <UserLogsTableText>
                        <UserProfilePicsAndNameContainer>
                          <UserImageContainer>
                            {getInitials(row.name)}
                          </UserImageContainer>
                          <CommonText
                            fontWeight="400"
                            fontSize=" 14px"
                            color={`${theme.colors.linkActiveTextColor}`}
                            fontFamily="matter-regular"
                            style={{
                              width: "100px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {row.name}
                          </CommonText>
                        </UserProfilePicsAndNameContainer>
                      </UserLogsTableText>
                      <UserLogsTableText>
                        <CommonText
                          fontWeight="400"
                          fontSize=" 14px"
                          color={`${theme.colors.linkActiveTextColor}`}
                          fontFamily="matter-light"
                          style={{
                            width: "160px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {row.email}
                        </CommonText>
                      </UserLogsTableText>
                      <UserLogsTableText
                        style={{
                          width: "120px",
                        }}
                      >
                        {row.activity}
                      </UserLogsTableText>
                      <UserLogsTableText
                        style={{
                          width: "120px",
                        }}
                      >
                        {row.date}
                      </UserLogsTableText>
                      <UserLogsTableText>
                        <UserStatusContainer status={row.authorized}>
                          {row.authorized}
                        </UserStatusContainer>
                      </UserLogsTableText>
                      <UserLogsTableText>
                        <UserStatusContainer status={row.status}>
                          {row.status}
                        </UserStatusContainer>
                      </UserLogsTableText>
                      <UserLogsTableText>
                        <UserOptionContainer>
                          <OptionContainer>
                            <SpowseImage
                              src={AppIcons.optionIcon}
                              alt="img"
                              width="auto"
                            />
                          </OptionContainer>
                        </UserOptionContainer>
                      </UserLogsTableText>
                    </TableTr>
                  ))}
                </tbody>
              </table>
              <PaginationWrapper>
                <ReactPaginate
                  previousLabel={`🡠   Previous`}
                  nextLabel={"Next 🡢"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(userLogsData.length / usersPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  nextClassName="n"
                  activeClassName={"activeClass"}
                />
              </PaginationWrapper>
            </UserLogsDataWrapper>
          </UserLogsContent>
        </UserLogsContentWrapper>
      </UserLogsWrapper>
    </ExpirationChecker>
  );
};
export default UserLogs;

