import axios, { AxiosRequestHeaders, AxiosInstance, AxiosError } from 'axios';
import { Utils } from '../utilities/utils';

class ApiService {
  private axiosInstance: AxiosInstance;
  private readonly baseURL: string = Utils.getAppUrl();

  constructor(timeout: number = 10000) {
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout,
    });
  }

  private handleError(error: any) {
    return {
      success: false,
      message:
        error.response?.data?.message || error.message || 'Unknown error',
      status: error.response?.status,
      data: error.response?.data,
    };
  }

  public async getData<T>(
    endpoint: string,
    params?: Record<string, any>,
    headers?: any,
  ): Promise<{
    success: boolean;
    data: T | null;
    message?: string;
    status?: number;
  }> {
    try {
      const response = await this.axiosInstance.get<T>(endpoint, {
        params,
        headers,
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error fetching data', error);
      return this.handleError(error as AxiosError);
    }
  }

  public async postData<T, R>(
    endpoint: string,
    data: T,
    headers?: any,
  ): Promise<{
    success: boolean;
    data: R | null;
    message?: string;
    status?: number;
  }> {
    try {
      const response = await this.axiosInstance.post<R>(endpoint, data, {
        headers,
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error posting data', error);
      return this.handleError(error as AxiosError);
    }
  }

  public async putData<T, R>(
    endpoint: string,
    data: T,
    headers?: AxiosRequestHeaders,
  ): Promise<{
    success: boolean;
    data: R | null;
    message?: string;
    status?: number;
  }> {
    try {
      const response = await this.axiosInstance.put<R>(endpoint, data, {
        headers,
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error updating data', error);
      return this.handleError(error as AxiosError);
    }
  }

  public async deleteData<R>(
    endpoint: string,
    headers?: AxiosRequestHeaders,
  ): Promise<{
    success: boolean;
    data: R | null;
    message?: string;
    status?: number;
  }> {
    try {
      const response = await this.axiosInstance.delete<R>(endpoint, {
        headers,
      });
      return { success: true, data: response.data };
    } catch (error) {
      console.error('Error deleting data', error);
      return this.handleError(error as AxiosError);
    }
  }
}

export default ApiService;
