import React, { useState } from "react";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { CommonText } from "../../core/common/reuseables/text_styles";
import { AppIcons } from "../../core/utilities/app_icons";
import { theme } from "../../theme/theme";
import Header from "../header/Header";
import SideBar from "../side_bar/SideBar";
import { MarketingWrapper, MarketingContentWrapper, MarketingContent, MarketingHeader, MarketingHeaderButton } from "./Marketing_styles";
import EmailMarketing from "./widgets/Email_marketing/EmailMarketing";
import SocialMediaMarketing from "./widgets/Social_media_marketing/SocialMediaMarketing";
import Seo from "./widgets/seo/Seo";
import ExpirationChecker from "../../core/common/reuseables/ExpirationChecker";

type MarketingProps = {};

const Marketing: React.FC<MarketingProps> = () => {
  const [marketingType, setMarketingType] = useState("emailMarketing");

  const handleSetMarketingType = (type: string) => {
    setMarketingType(type);
  }
  return (
    <ExpirationChecker>
      <MarketingWrapper>
        <Header headingText="Marketing" />
        <SideBar />
        <MarketingContentWrapper>
          <MarketingContent>
            <MarketingHeader>
              <MarketingHeaderButton
                background={
                  marketingType === "emailMarketing"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  marketingType === "emailMarketing"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  marketingType === "emailMarketing"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleSetMarketingType("emailMarketing");
                }}
              >
                <SpowseImage
                  src={AppIcons.sms}
                  alt="icon"
                  width="auto"
                  color={`${marketingType === "emailMarketing"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${marketingType === "emailMarketing"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Email Marketing
                </CommonText>
              </MarketingHeaderButton>
              <MarketingHeaderButton
                background={
                  marketingType === "social"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  marketingType === "social"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  marketingType === "social"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleSetMarketingType("social");
                }}
              >
                <SpowseImage
                  src={AppIcons.campaignIcon}
                  alt="icon"
                  width="auto"
                  color={`${marketingType === "social"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${marketingType === "social"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  Social Media Management
                </CommonText>
              </MarketingHeaderButton>
              <MarketingHeaderButton
                background={
                  marketingType === "seo"
                    ? `${theme.colors.backgroundColor}`
                    : `transparent`
                }
                textColor={
                  marketingType === "seo"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                }
                borderColor={
                  marketingType === "seo"
                    ? `${theme.colors.borderColor}`
                    : `transparent`
                }
                onClick={() => {
                  handleSetMarketingType("seo");
                }}
              >
                <SpowseImage
                  src={AppIcons.document}
                  alt="icon"
                  width="auto"
                  color={`${marketingType === "seo"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                />
                <CommonText
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                  fontFamily="matter-medium"
                  color={`${marketingType === "seo"
                    ? `${theme.colors.linkActiveTextColor}`
                    : `${theme.colors.linkInActiveTextColor}`
                    }`}
                >
                  SEO
                </CommonText>
              </MarketingHeaderButton>
            </MarketingHeader>
            {
              marketingType === "emailMarketing" ? <EmailMarketing /> : marketingType === "seo" ? <Seo /> : <SocialMediaMarketing />
            }
          </MarketingContent>
        </MarketingContentWrapper>
      </MarketingWrapper>
    </ExpirationChecker>
  );
};
export default Marketing;
