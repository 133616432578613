import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { CommonText } from './text_styles';
import { ErrorContainer } from '../../../presentations/side_bar/SideBar_styles';
import { SpowseImage } from './spowse_image';
import { HeightGap } from './gap';
import { SpowseButton } from './spowse_button';
import { useNavigate } from 'react-router-dom';

interface ExpirationCheckerProps {
    children: React.ReactNode;
}

const ExpirationChecker: React.FC<ExpirationCheckerProps> = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const checkTokenExpiration = () => {
            const loginTimeString = localStorage.getItem('loginTime');
            if (!loginTimeString) return;

            const loginTime = new Date(loginTimeString);
            const currentTime = new Date();
            const timeDifference = (currentTime.getTime() - loginTime.getTime()) / 1000 / 60;

            if (timeDifference > 300000 && !isModalOpen) {
                setIsModalOpen(true);
            }
        };

        const interval = setInterval(checkTokenExpiration, 1000 * 60);

        // Initial check on component mount
        checkTokenExpiration();

        return () => clearInterval(interval);
    }, [isModalOpen]);



    return (
        <>
            {isModalOpen && <Modal />}
            {children}
        </>
    );
};

export default ExpirationChecker;

interface ModalProps {

}

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255, 255, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
  `;

const ModalContent = styled.div<{ width?: string }>`
    background: white;
    padding: 30px;
    border-radius: 8px;
    width:500px;
    max-width: 1000px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border: 1px solid ${theme.colors.borderColor};
    z-index: 11;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  `;



const Modal: React.FC<ModalProps> = () => {
    const navigate = useNavigate();

    return (
        <ModalOverlay>
            <ModalContent onClick={(e: any) => e.stopPropagation()}>

                <ErrorContainer>
                    <SpowseImage src="/assets/icons/octicon_alert-24.svg" alt="img" width="auto" />
                </ErrorContainer>
                <HeightGap height="2rem" />
                <CommonText
                    fontFamily={"matter-bold"}
                    fontSize="30px"
                >Token Expired
                </CommonText>
                <HeightGap height="10px" />
                <CommonText
                    fontSize="14px"
                    color={`${theme.colors.linkInActiveTextColor}`}
                >
                    Your session has expired. Please log in to renew your access token and continue using the application.
                </CommonText>
                <HeightGap height="1.7rem" />
                <SpowseButton onClick={() => {
                    navigate("/login", { replace: true, })
                }}>Login</SpowseButton>
            </ModalContent>
        </ModalOverlay>
    );
};

