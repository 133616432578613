import styled from 'styled-components';
import { theme } from '../../theme/theme';
import { Link } from 'react-router-dom';

interface HeaderProbs {
  active?: any;
  backgroundColor?: any;
  textColor?: any;
}

export const SideBarWrapper = styled.section`
  width: 20%;
  background: ${theme.colors.backgroundColor};
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding: 1.5rem;
  z-index: 5;

  @media (min-width: 1300px) {
    width: 16%;
  }
`;

export const SideBarContent = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const CustomLink = styled(Link)<HeaderProbs>`
  text-decoration: none;
  display: flex;
  border: 1px solid
    ${(probs) =>
      probs.active ? theme.colors.linkActiveBorderColor : 'transparent'};
  background: ${(probs) => (probs.active ? theme.colors.white : 'transparent')};
  box-shadow: ${(probs) =>
    probs.active ? theme.boxShadows.primaryBoxShadow : 'none'};
  gap: 7px;
  padding: 0 10px;
  height: 40px;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 11px;

  &:hover {
    border: 1px solid ${theme.colors.linkActiveBorderColor};
    transition: 300ms all ease-in;
    box-shadow: ${theme.boxShadows.primaryBoxShadow};
  }
`;

export const LogOutContainer = styled.button`
  text-decoration: none;
  display: flex;
  border: 0;
  background: transparent;
  gap: 7px;
  padding: 0 10px;
  height: 40px;
  align-items: center;
  border-radius: 7px;
  margin-bottom: 11px;
  width: 100%;

  &:hover {
    border: 1px solid ${theme.colors.linkActiveBorderColor};
    transition: 300ms all ease-in;
    box-shadow: ${theme.boxShadows.primaryBoxShadow};
  }
`;

export const ErrorContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #ffece5;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
