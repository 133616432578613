import React from "react";
import {
  SubscriptionContent,
  SubscriptionContentWrapper,
  SubscriptionWrapper,
} from "./subscriptions_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import SubscriptionLeftSection from "./widgets/Subscription_Left_Section/SubscriptionLeftSection";
import SubscriptionRightSection from "./widgets/Subscription_Right_Section/SubscriptionRightSection";
import ExpirationChecker from "../../core/common/reuseables/ExpirationChecker";

type SubscriptionProps = {};

const Subscription: React.FC<SubscriptionProps> = () => {
  return (
    <ExpirationChecker>
      <SubscriptionWrapper>
        <SideBar />
        <Header headingText="Subscriptions" />
        <SubscriptionContentWrapper>
          <SubscriptionContent>
            <SubscriptionLeftSection />
            <SubscriptionRightSection />
          </SubscriptionContent>
        </SubscriptionContentWrapper>
      </SubscriptionWrapper>
    </ExpirationChecker>
  );
};
export default Subscription;
